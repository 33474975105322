import { useEffect, useState } from "react";
import { useMemberstack } from "@memberstack/react"
import { useNavigate } from "react-router-dom"
import loadingGif from '../../assets/images/loading.gif'

export default function SignUp() {

  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [name, setName] = useState('')
  const [msg, setMsg] = useState<string>("")

  const memberstack = useMemberstack();
  const navigation = useNavigate();

  const confirm = async () => {
    try {
      // first signup the member
      if (!email || !password || !name || !phone) {
        setMsg('Please input all values.')
        setTimeout(() => { setMsg("") }, 5000)
        return
      }
      setLoading(true);

      try {
        let result: any = await memberstack.signupMemberEmailPassword({
          email: email,
          password: password,
        });

        localStorage.setItem('email', email)
        let res: any = await memberstack.updateMember({
          customFields: {
            name,
            phone
          },
        })
        setLoading(false)
      } catch (err: any) {
        setMsg(err.message)
        setTimeout(() => { setMsg("") }, 5000)
        setLoading(false)
        return
      }

      try {
        // after successful signup, redirect them to the stripe checkout
        await memberstack.purchasePlansWithCheckout({
          priceId: `${process.env.REACT_APP_MONTHLY_ID}`, // required
          // cancelUrl: "http://192.168.143.123:3000/#/account/login",
          // successUrl: "http://192.168.143.123:3000/#/account/dashboard",
          cancelUrl: `${process.env.REACT_APP_DEPLOY}/#/account/login`,
          successUrl: `${process.env.REACT_APP_DEPLOY}/#/account/dashboard`,

          // cancelUrl: "https://prod.yndevops.com/#/account/login",
          // successUrl: "https://prod.yndevops.com/#/account/dashboard",
          autoRedirect: true,
        })
        setLoading(false)
      } catch (err: any) {
        setLoading(false)
        setMsg(err.message)
        setTimeout(() => { setMsg("") }, 5000)
      }
    } catch (err: any) {
      setMsg(err.message!) // email already taken, etc.
      setTimeout(() => { setMsg("") }, 5000)
    }
  }

  useEffect(() => {
    document.getElementById("blogMemberId")?.focus();
  }, [])

  return (
    <>
      <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[15px] md:pb-[50px] pb-[30px] md:text-[12px] text-[16px]">
        <div className={isLoading ? "absolute flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px]" : "flex-1 flex flex-col max-[480px]:justify-start md:justify-start justify-center gap-y-[30px]"}>
          <div className="block md:hidden">
            <div className="hidden xs:block">
              <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                <span className="text-center">Y IS PRESENTED IN MODE</span>
              </div>
            </div>
          </div>
          <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
            <div className="flex w-full flex-col gap-y-[3.6px]">
              <span className="w-full">Sign up</span><br />
              <input placeholder="Name" type="text" value={name} onChange={(evt) => setName(evt.target.value)} className="bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
              <input id="blogMemberId" placeholder="Email address" type="email" value={email} onChange={(evt) => setEmail(evt.target.value)} className="bg-transparent outline-none w-100% placeholder:text-black text-[16px] md:text-[12px]" />
              <input placeholder="Phone number" type="text" value={phone} onChange={(evt) => setPhone(evt.target.value)} className="bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
            </div>

            <input placeholder="Password" type="password" value={password} onChange={(evt) => setPassword(evt.target.value)} className="mt-[15px] bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
            <div className="mt-[25px]">
              {
                !isLoading ?
                  <span className="cursor-pointer mt-10" onClick={confirm}>Submit</span> :
                  <span className="mt-10">Please wait...</span>
              }
            </div>
          </div>
        </div>
        {
          isLoading
            ? <div className="flex flex-col justify-center items-center h-screen">
              {/* <img width={'15%'} src={loadingGif} alt="loading..." /> */}
            </div>
            : <div className="flex flex-row gap-x-[20px]">
              <span className="cursor-pointer">{msg && msg}</span>
            </div>
        }
        <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
          <div className="flex mt-10 flex-row items-center gap-x-[20px]">
            <span onClick={() => navigation('/home')} className="cursor-pointer">Go back</span>
          </div>
        </div>
      </div>
    </>
  )
}