import { useState } from "react";
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import { useAuth, useMemberstack } from '@memberstack/react'

export default function Auth() {
    const memberstack = useMemberstack();

    const { isLoggedIn } = useAuth();
    const [isLoading, setLoading] = useState(false)
    const [protectPasswd, setProtectPasswd] = useState('')

    const navigation = useNavigate();
    const toAccount = () => navigation('/account/dashboard')
    const toLogout = () => (localStorage.setItem('email', ''), memberstack.logout(), navigation(`/home`))
    const toSignUp = () => navigation('/account/signup')
    const toSignIn = () => navigation('/account/login')

    const toSmartForm = async () => {
        try {
            setLoading(true)
            let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/cms/auth/protect`, {
                tool: protectPasswd!
            })
            if (res.status === 200) {
                setLoading(false)
                navigation('/home')
            }
        } catch (err: any) {
            setLoading(false)
        }
    }

    return (
        <div className="w-full h-full flex flex-col justify-center px-[35px] sm:px-[33px] xs:py-[50px] md:pt-[0px] text-[16px] md:text-[12px] md:py-[50px] pt-[180px] pb-[30px]">
            <div className="flex-1 flex flex-col md:justify-center gap-y-[30px]">
                <div className="block md:hidden">
                    <div className="hidden xs:block">
                        <div className="flex-1 flex flex-col justify-center gap-y-[20px] md:text-[14px] text-[16px]">
                            <span className="text-center">Y IS PRESENTED IN   MODE</span>
                        </div>
                    </div>
                </div>

                <div className="md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px] leading-4">
                    <div className="flex-1 flex flex-col gap-y-[10px]">
                        <div className="flex flex-col gap-y-[5px]">
                            <span className="">Enter a phone number or email</span>
                            <span className="">Or just tap submit</span>
                        </div>
                        <input placeholder="|" onChange={(e) => setProtectPasswd(e.target.value)} value={protectPasswd!} id="blogMemberId" type="text" className="bg-transparent outline-none w-full placeholder:text-[#8f8f8f] placeholder:sm:text-transparent mb-5 text-[16px] md:text-[12px]" autoFocus />

                        {
                            isLoading
                                ? <span>Please wait...</span>
                                : <span onClick={() => { toSmartForm() }} className="cursor-pointer">Submit</span>
                        }

                    </div>
                </div>
            </div>
            <div className="md:block max-[500px]:block mt-[-5px] block xs:hidden text-[16px] md:text-[12px] pt-[5px]">
                {
                    isLoggedIn ?
                        <div className="flex-1 flex flex-col gap-x-[10px]">
                            <span onClick={toAccount} className="cursor-pointer">Account</span>
                            <span onClick={toLogout} className="block cursor-pointer">Log out</span>
                        </div>
                        : <div className="flex-1 flex flex-col gap-x-[10px]">
                            <span onClick={toSignUp} className="cursor-pointer">Sign up</span>
                            <span onClick={toSignIn} className="block cursor-pointer">Log in</span>
                        </div>
                }
            </div>
        </div>
    )
}