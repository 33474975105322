import ReactDOM from "react-dom/client";
import { MemberstackProvider } from "@memberstack/react";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./app";
import "@fortawesome/fontawesome-free/css/all.min.css";
import NotificationProvider from "./socket/useNotifications";
const config = {
  publicKey:
    process.env.REACT_APP_MEMBERSTACK_PK || "pk_sb_199017f5ce8ed7e7f641",
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MemberstackProvider config={config}>
    <NotificationProvider>
      <App />
    </NotificationProvider>
  </MemberstackProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
