import { useCallback, useEffect, useState } from 'react'
import { getDateFormat1 } from '../utils';
import { useNavigate, useParams } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import { useAuth } from '@memberstack/react'
import axios from "axios"
import heart from "../assets/images/heart.svg"
import heartO from "../assets/images/heart-o.svg"

export default function Edition() {
    const memberstack = useMemberstack();
    const { gatedID } = useParams<{ gatedID: string }>()

    const [isShow, setShow] = useState(false)
    const [showType, setShowType] = useState(0);
    const [showList, setShowList] = useState<boolean[]>();
    const [isButtonsShow, setButtonsShow] = useState(false);
    const [isAllOpen, setAllOpen] = useState(false);
    const [gatedList, setGatedList] = useState<any>()
    const [addList, setAddList] = useState<any[]>([])
    const [isFirstPage, setFirstPage] = useState(true)
    const [msg, setMsg] = useState('')
    const [timeList, setTimeList] = useState<any[]>([])
    const { isLoggedIn } = useAuth();

    const BASEURL = process.env.REACT_APP_BASE_URL
    const IMAGE = process.env.REACT_APP_IMAGE

    const navigation = useNavigate();
    const toSignUp = () => navigation('/account/signup')
    const toSignIn = () => navigation('/account/login')
    const toAccount = () => navigation('/account/dashboard')
    const toGated = () => navigation('/gated')
    const toViewmemo = () => navigation("/home")

    const toLogout = () => (
        localStorage.setItem('email', ''), memberstack.logout(), navigation(`/home`)
    )

    /**
     * GET timestamp published memos in that day when execute home page.
     * SET|Array ...['10AM', '3PM', '8PM']
     */
    const getGatedList = useCallback(async () => {
        try {
            let list: any = await axios.get(`${process.env.REACT_APP_EDITION}/get_gated_list/${gatedID}`)
            setGatedList(list.data.data)
        } catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => { getGatedList() }, [getGatedList])


    const createMarkup = (e: string) => {
        return { __html: e };
    }

    return (
        <div className="w-full h-full flex flex-col md:pt-[70px] px-[35px] sm:px-[33px] md:py-[50px] pt-[15px] pb-[30px] md:text-[12px] text-[16px]">
            <div className="flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px] h-[90%]">
                <div className="block md:hidden">
                    <div className="hidden xs:block">
                        <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                            <span className="text-center">Y IS PRESENTED IN MODE</span>
                        </div>
                    </div>
                </div>
                <div className='md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px] h-[100%]'>
                    {/* <div className="flex flex-row h-[7%] max-[310px]:h-[11%] max-[205px]:h-[14%] max-[185px]:h-[18%] flex-wrap items-start gap-x-[10px]">
                        <span onClick={() => { navigation('/gated') }} className={`${isButtonsShow && isShow ? "opacity-100 cursor-pointer" : "opacity-0"} duration-500`}>Gated</span>
                    </div> */}

                    {
                        gatedList &&
                        <div className="flex-1 flex mt-[5px] flex-col h-[93%] max-[310px]:h-[89%] max-[205px]:h-[86%] max-[185px]:h-[80%] gap-y-[22px] mainContent">
                            {/* ///////////////////////////////////////////////////////////////// */}
                            <div className="flex flex-col items-start">
                                <span className="cursor-pointer width-[100%] break-words whitespace-pre-line  mb-[30px]">{gatedList.title && gatedList.title}</span>
                                <div className={`flex flex-col gap-y-[20px] "h-full mt-[30px]"} transition-all duration-500 w-[100%] overflow-hidden`}>
                                    {
                                        gatedList.media &&
                                        <div className="w-full overflow-hidden rounded-xl">
                                            <img width={'100%'} src={`${IMAGE}/gated/${gatedList.media}`} alt="" />
                                        </div>
                                    }

                                    <span className="md:text-[12px] text-[16px] mt-[2px] text-gray-500 width-[100%] break-words whitespace-pre-line mb-1">{gatedList.media_title && gatedList.media_title}</span>

                                    <div dangerouslySetInnerHTML={createMarkup(gatedList.memo_content)} className="break-words whitespace-pre-line flex flex-col gap-y-[20px]">
                                        {/* {
                        item.memo_content && item.memo_content.length &&
                        item.memo_content.split('\n').map((sentence: string, key: number) => (
                          <span key={key} className="width-[100%] break-words whitespace-pre-line">{sentence}</span>
                        ))
                      } */}
                                    </div>
                                </div>
                            </div>
                            {/* ///////////////////////////////////////////////////////////////// */}
                        </div>
                    }

                </div>
                <div>
                </div>
            </div>
            <div className='md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px] relative  ]'>
                {
                    isFirstPage && isLoggedIn ?
                        <div className="block mt-[-5px]">
                            <div className="flex-1 flex flex-col gap-x-[20px]">
                                <span onClick={toAccount} className="cursor-pointer">Account</span>
                                <span onClick={toViewmemo} className="block cursor-pointer">View memos</span>
                                <span onClick={toGated} className="block cursor-pointer">Go back</span>
                            </div>
                        </div> :
                        isFirstPage && !isLoggedIn
                            ? <div className="block">
                                <div className="flex-1 flex flex-col gap-x-[20px] mt-[-5px]">
                                    <span onClick={toSignUp} className="cursor-pointer">Sign up</span>
                                    <span onClick={toSignIn} className="block cursor-pointer">Log in</span>
                                </div>
                            </div> : <></>
                }
            </div>
        </div>
    )
}

