import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useMemberstack } from "@memberstack/react"
import loadingGif from '../assets/images/loading.gif'

export default function Settings() {

    const memberstack = useMemberstack()

    const [isLoading, setLoading] = useState(false);
    const [getMemoMethodByEmail, setGetMemoMethodByEmail] = useState(false)
    const [getPDFMethodBySMS, setGetPDFMethodBySMS] = useState(false)
    const [getMemoMethodBySMS, setGetMemoMethodBySMS] = useState(false)
    const [getPDFMethodByEmail, setGetPDFMethodByEmail] = useState(false)
    const [combineStatus, setCombineStatus] = useState(false)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [email, setEmail] = useState('')

    const navigation = useNavigate();
    const changePlan = () => navigation('/account/change-plan');
    const changePassword = () => navigation('/changepassword')
    const toMemo = () => navigation(`/home`)
    const toArchive = () => navigation('/archive')
    const toGated = () => navigation('/gated')

    const confirm = async () => {
        setMsg('')
        if ((!getMemoMethodByEmail && !getPDFMethodBySMS && getMemoMethodBySMS && !getPDFMethodByEmail && !combineStatus) && (!name || !phone || !email)) {
            setMsg("Please check value to update")
            setTimeout(() => {
                setMsg("")
            }, 5000)
            return
        }

        try {
            setLoading(true)
            await memberstack.updateMember({
                customFields: {
                    getMemoMethodByEmail: getMemoMethodByEmail, getPDFMethodBySMS: getPDFMethodBySMS, getMemoMethodBySMS: getMemoMethodBySMS, getPDFMethodByEmail: getPDFMethodByEmail, combineStatus, name, phone
                }
            })

            if (email !== localStorage.getItem('email')) {
                await memberstack.updateMemberAuth({ email: email! })
                localStorage.setItem('email', email!)
            }

            setLoading(false)
            setMsg("Update succeeded")
            setTimeout(() => { setMsg("") }, 5000)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    const getInit = useCallback(async () => {
        let res: any = await memberstack.getCurrentMember()
        let items: any
        if (res && res.data && res.data.customFields) {
            items = res.data.customFields as {
                getMemoMethodByEmail?: boolean;
                getPDFMethodBySMS?: boolean;
                getMemoMethodBySMS?: boolean;
                getPDFMethodByEmail?: boolean;
                combineStatus?: any;
                name?: any;
                phone?: any;
            }
            setGetMemoMethodByEmail(items.getMemoMethodByEmail!)
            setGetPDFMethodBySMS(items.getPDFMethodBySMS!)
            setGetMemoMethodBySMS(items.getMemoMethodBySMS!)
            setGetPDFMethodByEmail(items.getPDFMethodByEmail!)
            setCombineStatus(items.combineStatus!)
            setName(items.name!)
            setPhone(items.phone!)
            setEmail(res.data.auth.email!)
        }
    }, [getMemoMethodByEmail, getPDFMethodBySMS, getMemoMethodBySMS, getPDFMethodByEmail, name, phone, combineStatus])

    useEffect(() => {
        getInit()
    }, [])

    const { isLoggedIn } = useAuth();

    return (
        <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[15px] md:pb-[50px] pb-[35px] md:text-[12px] text-[16px]">
            <div className={isLoading ? "flex-1 flex flex-col max-[480px]:justify-start md:justify-start justify-center gap-y-[26px] h-[93%]" : "flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[26px] h-[93%]"}>
                <div className="block md:hidden">
                    <div className="hidden xs:block">
                        <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                            <span className="text-center">Y IS PRESENTED IN MODE</span>
                        </div>
                    </div>
                </div>
                <div className="h-full md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                    <div className="mb-[10%] truncate max-[480px]:mb-5">
                        <span className="">Subscription options</span>
                    </div>
                    <div className="flex flex-col gap-y-[3.6px] mb-[10%] max-[480px]:mb-5">
                        <span><input type="text" onChange={(e) => setName(e.target.value)} defaultValue={name} className="bg-transparent outline-none w-full text-[16px] md:text-[12px]" /></span>
                        <span><input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" defaultValue={email!} className="w-full bg-transparent outline-none text-[16px] md:text-[12px]" /></span>
                        <span><input type="text" onChange={(e) => setPhone(e.target.value)} placeholder="Phone number" defaultValue={phone} className="bg-transparent outline-none w-full text-[16px] md:text-[12px]" /></span>
                    </div>

                    <div className="flex flex-col gap-y-[3.6px] w-full mb-[10%] max-[480px]:mb-5">
                        <div className="flex flex-row items-center justify-between">
                            <span onClick={() => {
                                setGetMemoMethodByEmail(!getMemoMethodByEmail)
                                setCombineStatus(false)
                            }} className='cursor-pointer truncate'>Get memo via email</span>
                            <span>{getMemoMethodByEmail ? "✓" : ''}</span>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                            <span onClick={() => {
                                setGetMemoMethodBySMS(!getMemoMethodBySMS)
                                setCombineStatus(false)
                            }} className="cursor-pointer truncate">Get memo via SMS</span>
                            <span>{getMemoMethodBySMS ? "✓" : ''}</span>
                        </div>
                        {/* <div className="flex flex-row items-center justify-between">
                        <span onClick={() => {
                            setGetPDFMethodByEmail(!getPDFMethodByEmail)
                            setCombineStatus(false)
                        }} className="cursor-pointer">Get PDF via email</span>
                        <span>{getPDFMethodByEmail ? "✓" : ''}</span>
                    </div>
                    <div className="flex flex-row items-center justify-between">
                        <span onClick={() => {
                            setGetPDFMethodBySMS(!getPDFMethodBySMS)
                            setCombineStatus(false)
                        }} className="cursor-pointer">Get PDF via SMS</span>
                        <span>{getPDFMethodBySMS ? "✓" : ''}</span>
                    </div> */}
                    </div>

                    <div className="flex flex-col gap-x-[15px] mb-[5%] max-[480px]:mb-3">
                        <div className="flex flex-row">
                            <span className="cursor-pointer truncate" onClick={() => {
                                setCombineStatus(!combineStatus)
                                setGetMemoMethodByEmail(false)
                                setGetMemoMethodBySMS(false)
                                setGetPDFMethodByEmail(false)
                                setGetPDFMethodBySMS(false)
                            }} >Combine into one evening digest</span>
                            <span className="ml-3">{combineStatus ? '✓' : ''}</span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-[3.6px] mb-[10%] max-[480px]:mb-5">
                        <span onClick={changePlan} className="cursor-pointer truncate">Change plan</span>
                    </div>

                    <div className="flex flex-col gap-y-[3.6px] mb-[5%] max-[480px]:mb-3">
                        <span onClick={changePassword} className="cursor-pointer truncate">Change password</span>
                    </div>

                    <div className="flex flex-col gap-y-[3.6px]">
                        {
                            isLoading ?
                                <span className="truncate">Please wait...</span>
                                : <span onClick={confirm} className="cursor-pointer truncate">Submit</span>
                        }
                        <span onClick={toMemo} className="cursor-pointer truncate">{msg && msg}</span>
                    </div>
                </div>
            </div>
            <div className="h-[7%] md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px] relative bottom-[16px]">
                <div className="flex flex-col gap-x-[20px]">
                    {isLoggedIn && <span onClick={toArchive} className="cursor-pointer truncate">Archive</span>}
                    <span onClick={toGated} className="cursor-pointer truncate">Gated content</span>
                    <span onClick={toMemo} className="cursor-pointer truncate">View memos</span>
                </div>
            </div>
            {
                // isLoading &&
                // <div className="flex flex-col justify-center items-center h-screen">
                //     <img width={'15%'} src={loadingGif} alt="loading..." />
                // </div>
            }
        </div>
    )
}