import { useCallback, useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom"

const Archive = () => {

    const BASEURL = process.env.REACT_APP_BASE_URL
    const [archiveList, setArchiveList] = useState<any[]>([])
    const navigation = useNavigate()

    useEffect(() => { getArchiveList() }, [])

    const convertArray = (list: any[]) => {
        if (!list || (list && !list.length)) return []
        let newList: any = []
        let timeList: any = ["", "", ""]
        list.map((item: any, key: number) => {
            if (!newList.length) {
                newList.push({ date: item.date, timeList: ["", "", ""] })
            } else {
                let check: any = newList.filter((newItem: any, i: number) => newItem.date === item.date)
                if (check && !check.length) newList.push({ date: item.date, timeList: ["", "", ""] })
            }
        })
        if (newList && newList.length) {
            newList.map((item: any, key: number) => {
                timeList = list.filter((originItem: any, i: number) => originItem.date === item.date)
                if (timeList && timeList.length) {
                    timeList.map((item: any, i: number) => {
                        if (item.time_stamp === '10AM') newList[key].timeList[0] = item.time_stamp
                        else if (item.time_stamp === '3PM') newList[key].timeList[1] = item.time_stamp
                        else newList[key].timeList[2] = item.time_stamp
                    })
                }
            })
        }
        return newList
    }

    const getArchiveList = useCallback(async () => {
        try {
            // let list: any = await axios.get(`${BASEURL}/edition/prod/get_list`)
            let list: any = await axios.get(`${process.env.REACT_APP_EDITION}/get_list`)
            if (list.status !== 200) return

            if (list && list.data && list.data.length) {
                let newList = convertArray(list.data)
                setArchiveList([...newList])
                // setArchiveList([{date:'2-23-24', timeList:['3AM', '8PM', '10AM']}, {date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']},{date:'2-23-24', timeList:['3AM', '8PM', '10AM']}])
            }
            else setArchiveList([])
        } catch (err) {
            console.log(err)
        }
    }, [])

    return (
        <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[45px] md:py-[50px] pb-[30px] text-[16px] md:text-[12px] max-[479px]:justify-start md:justify-start justify-center">
            <div className="block md:hidden">
                <div className="hidden xs:block">
                    <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                        <span className="text-center">Y IS PRESENTED IN MODE</span>
                    </div>
                </div>
            </div>
            <div className="md:block md:h-[93%] h-[92%] max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                <div className="flex-1 flex h-full flex-col justify-start gap-y-[26px] mainContent">
                    {
                        archiveList && archiveList.length
                            ? archiveList.map((list, key) => (
                                <div key={key} className="flex flex-row flex-wrap items-start gap-x-[10px]">
                                    <div className="cursor-pointer mr-1">
                                        <span>{list && list.date ? list.date.replaceAll('-', " ") : ''}</span>
                                    </div>
                                    {
                                        list.timeList && list.timeList.length
                                            ? list.timeList
                                                .map((time: any, i: any) => (<div className={"cursor-pointer max-[180px]:w-full"} onClick={() => time ? navigation(`/edition/${list.date}-${time}`) : {}} key={i}>
                                                    <span>{time}</span>
                                                </div>))
                                            : ''
                                    }
                                </div>
                            )) : <></>
                    }
                </div>
            </div>
            <div className="md:block h-[7%] max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                <div className="flex mt-[18px] flex-row items-center mr-20 gap-x-[20px]">
                    <span onClick={() => navigation('/home')} className="cursor-pointer">Exit archive</span>
                </div>
            </div>
        </div>
    )
}

export default Archive