import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import loadingGif from '../../assets/images/loading.gif'

export default function Login() {

  const memberstack = useMemberstack();

  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [msg, setMsg] = useState<string>("")

  const navigation = useNavigate();

  const confirm = async () => {
    try {
      // Signup the member
      if (!email || !password) {
        setMsg('Please input all values.')
        setTimeout(() => { setMsg("") }, 5000)
        return
      }
      setLoading(true);

      let priceID = ''
      let flag = false
      try {
        let res: any = await memberstack.loginMemberEmailPassword({
          email: email,
          password: password
        });
        if (res._internalUseOnly.message === 'You have successfully logged in.') {
          flag = true
        }
        if (res.data && res.data.member.planConnections && res.data.member.planConnections.length)
          priceID = res.data.member.planConnections[0].payment.priceId
        localStorage.setItem('email', email)
      } catch (err: any) {
        setLoading(false)
        setMsg(err.message)
        setTimeout(() => {
          setMsg("")
        }, 5000)
        return
      }
      if (!priceID && flag) {
        try {
          // after successful signup, redirect them to the stripe checkout
          await memberstack.purchasePlansWithCheckout({
            priceId: `${process.env.REACT_APP_MONTHLY_ID}`, // required
            // cancelUrl: "http://192.168.143.123:3000/#/account/login",
            // successUrl: "http://192.168.143.123:3000/#/account/dashboard",

            cancelUrl: `${process.env.REACT_APP_DEPLOY}/#/account/login`,
            successUrl: `${process.env.REACT_APP_DEPLOY}/#/account/dashboard`,

            //   cancelUrl: "https://prod.yndevops.com/#/account/login",
            // successUrl: "https://prod.yndevops.com/#/account/dashboard",
            autoRedirect: true,
          })
        } catch (err) {
          setLoading(false)
          console.log(err)
          navigation('/account/dashboard')
        }
      }
      setLoading(false)
      navigation('/account/dashboard')
    } catch (err: any) {
      console.log(err.message!) // email already taken, etc.
    }
  }

  const resetPassword = () => navigation(`/resetpassword`)

  useEffect(() => {
    document.getElementById("blogMemberId")?.focus();
  }, [])

  return (
    <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[15px] md:pb-[50px] pb-[30px] md:text-[12px] text-[16px]">
      <div className={isLoading ? 'flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px]' : "flex-1 flex flex-col max-[480px]:justify-start md:justify-start justify-center gap-y-[30px]"}>
        <div className="block md:hidden">
          <div className="hidden xs:block">
            <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
              <span className="text-center">Y IS PRESENTED IN MODE</span>
            </div>
          </div>
        </div>
        <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
          <div className="flex flex-col gap-y-[3.6px]">
            <span className="">Log in</span>
            <input placeholder="Email address" id="blogMemberId" type="email" value={email} onChange={(evt) => setEmail(evt.target.value)} className="mt-2 bg-transparent outline-none w-[100%] placeholder:text-black text-[16px] md:text-[12px]" />
            <input placeholder="Password" id="blogMemberPassword" type="password" value={password} onChange={(evt) => setPassword(evt.target.value)} className="bg-transparent outline-none w-[100%] placeholder:text-black text-[16px] md:text-[12px]" />
            {
              isLoading ?
                <span>Please wait...</span> :
                <span className="cursor-pointer" onClick={confirm}>Submit</span>
            }
          </div>
        </div>
        <span onClick={resetPassword} className="cursor-pointer md:block block xs:hidden md:text-[12px] text-[16px]">Forgot password</span>
      </div>
      {
        // isLoading
        //   ? <div className="flex flex-col justify-center items-center h-screen">
        //     {/* <span className="animate-spin border-blue-500 w-6 h-6 border-r-2 border-b-2  rounded-[20px]"></span>
        //   <span className="mt-5">Loading...</span> */}
        //     {/* <img width={'15%'} src={loadingGif} alt="loading..." /> */}
        //   </div>
        //   : <div className="flex-row gap-x-[20px]">
        //     <span className="cursor-pointer">{msg && msg}</span>
        //   </div>
        <div className="flex-row gap-x-[20px]">
          <span className="cursor-pointer">{msg && msg}</span>
        </div>
      }
      <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
        <div className="flex mt-10 flex-row items-center gap-x-[20px]">
          <span onClick={() => navigation('/home')} className="cursor-pointer">Go back</span>
        </div>
      </div>
    </div>
  )
}