
const getDateFormat1 = () => {
    let date = new Date();
    let month = date.getMonth()
    let day = date.getDate()
    let year = date.getFullYear()

    return `${month + 1}  ${day}  ${year % 100}`
}

const getLocalStorage = (type: any) => {
    return localStorage.getItem(type);
}

const setLocalStorage = (type: any, value: any) => {
    localStorage.setItem(type, value);
}

export {
    getDateFormat1,
    getLocalStorage,
    setLocalStorage
}