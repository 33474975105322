import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import { useAuth } from "@memberstack/react";
import Login from "./pages/auth/login";
import SignUp from "./pages/auth/signup";
import Resetpassword from "./pages/resetPassword";
import Auth from "./pages/auth";
import Edition from "./pages/edition";
import ChangePlan from "./pages/changePlan";
import Account from "./pages/account";
import ChangePassword from "./pages/changepassword";
import Archive from "./pages/archive";
import Gated from "./pages/gated";
import GatedView from "./pages/viewgated";

export default function Router() {
  const { isLoggedIn } = useAuth();

  return (
    <HashRouter>
      <Routes>
        <Route path="/" index element={<Auth />} />
        <Route path="/account/login" element={<Login />} />
        <Route path="/account/signup" element={<SignUp />} />
        <Route path="/resetpassword" element={<Resetpassword />} />
        <Route path="/home" element={<Edition />} />
        <Route path="/edition/:timestamp" element={<Edition />} />
        {isLoggedIn ? (
          <React.Fragment>
            <Route path="/archive" element={<Archive />} />
            <Route path="/account/change-plan" element={<ChangePlan />} />
            <Route path="/gated" element={<Gated />} />
            <Route path="/viewgated/:gatedID" element={<GatedView />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/account/dashboard" element={<Account />} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* <Route path="*" element={<Navigate to={'/login'} />} /> */}
          </React.Fragment>
        )}
      </Routes>
    </HashRouter>
  );
}
