import { PropsWithChildren, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import io, { Socket } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "http://localhost:8080";

enum Events {
  NEW_MEMO = "NEW_MEMO",
}

class NotificationSocket {
  static io: Socket | null = null;

  static getInstance() {
    if (!this.io) {
      this.io = io(SOCKET_URL, { autoConnect: true, path: "/socket" });
    }

    return this.io;
  }
}

const NotificationProvider = (props: PropsWithChildren) => {
  const hasAttachedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!hasAttachedRef.current) {
      const socket = NotificationSocket.getInstance();

      socket.on(Events.NEW_MEMO, (content: string) => toast(content));

      socket.connect();

      hasAttachedRef.current = true;
    }
  }, []);

  return (
    <>
      <Toaster position="top-center" />
      {props.children}
    </>
  );
};

export default NotificationProvider;
