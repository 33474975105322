import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import { getDateFormat1 } from '../utils';

export default function ResetPassword() {

  const navigation = useNavigate();

  const memberstack = useMemberstack();

  const [isLoading, setLoading] = useState(false);
  const [code, setCode] = useState('')
  const [email, setEmail] = useState<string>("")
  const [newPassword, setNewPassword] = useState<string>("")
  const [msg, setMsg] = useState<string>("")
  const [isEmailChecked, setEmailChecked] = useState(false)

  const confirm = async () => {
    if (!email) {
      setMsg("Please input your email.")
      setTimeout(() => {
        setMsg("")
      }, 5000)
      return
    }
    try {
      setMsg('')
      setLoading(true);

      if (isEmailChecked) {
        let res: any = await memberstack.resetMemberPassword({
          token: code,
          newPassword: newPassword
        })

        setLoading(false)
        setMsg(res._internalUseOnly.message!)
        setTimeout(() => { setMsg("") }, 5000)
        return
      }

      let res: any = await memberstack.sendMemberResetPasswordEmail({
        email: email
      })

      setLoading(false)
      setEmailChecked(true)
      setMsg(res.data)
      setTimeout(() => {
        setMsg("")
      }, 5000)
    } catch (e: any) {
      setLoading(false);
      setMsg(e.message);
      setTimeout(() => { setMsg("") }, 5000)
    }
  }

  useEffect(() => {
    document.getElementById("blogMmerId")?.focus();
  }, [])

  return (
    <div className="w-full pt-[15px] h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] md:pb-[50px] pb-[30px] text-[16px] md:text-[12px]">
      <div className="flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px] ">

        <div className="block md:hidden">
          <div className="hidden xs:block">
            <div className="flex-1 flex flex-col gap-y-[20px] md:text-[12px] text-[16px]">
              <span className="text-center">Y IS PRESENTED IN MODE</span>
            </div>
          </div>
        </div>
        <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
          <div className="mb-4">
            <span className="">{!isEmailChecked ? 'Forgot password' : "Check your email"}</span>
          </div>

          {
            isEmailChecked
              ? <div className="flex flex-col gap-y-[3.6px]">
                <input placeholder="6 digit code" type="text" value={code} onChange={(evt) => setCode(evt.target.value)} className="bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
                <input placeholder="New password" type="password" value={newPassword} onChange={(evt) => setNewPassword(evt.target.value)} className="bg-transparent outline-none w-full mb-6 placeholder:text-black text-[16px] md:text-[12px]" />
              </div>
              : <div className="flex flex-col gap-y-[3.6px] mb-6">
                <input placeholder="Email address" id="blogMemberId" type="email" value={email} onChange={(evt) => setEmail(evt.target.value)} className="bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
              </div>
          }
          {
            isLoading ?
              <span>Please wait...</span> :
              <span onClick={confirm} className="cursor-pointer">Reset password</span>
          }
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-[20px]">
        <span className="cursor-pointer">{msg && msg}</span>
      </div>
      <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
        <div className="flex mt-10 flex-row items-center gap-x-[20px]">
          <span onClick={() => navigation('/account/login')} className="cursor-pointer">Go back</span>
        </div>
      </div>
    </div>
  )
}