import { useCustomerPortal, MemberstackProtected, SignInModal } from "@memberstack/react"
import { useCallback, useEffect, useState } from "react";
import { useMemberstack } from '@memberstack/react'
import { useNavigate } from "react-router-dom"
import loadingGif from '../assets/images/loading.gif'

export default function ChangePlan() {

    const memberstack = useMemberstack();
    const navigation = useNavigate();

    const [plan, setPlan] = useState('yearly');
    const [isLoading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')
    const [phone, setPhone] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const confirm = async () => {
        if (!name || !phone) {
            setMsg("Please check items to update")
            setTimeout(() => { setMsg("") }, 5000)
            return
        }

        try {
            setLoading(true)
            if (email !== localStorage.getItem('email')) {
                await memberstack.updateMemberAuth({ email: email! })
                localStorage.setItem('email', email!)
            }

            await memberstack.updateMember({
                customFields: {
                    name, phone
                }
            })
            setLoading(false)
            setMsg('Successfully updated')
            setTimeout(() => {
                setMsg("")
            }, 5000)

        } catch (err: any) {
            setLoading(false)
            setMsg(err.message)
            setTimeout(() => { setMsg("") }, 5000)
        }
    }

    const updateMonthlyPlan = useCustomerPortal({
        priceIds: [`${process.env.REACT_APP_MONTHLY_ID}`],
    });

    const updateYearlyPlan = useCustomerPortal({
        priceIds: [`${process.env.REACT_APP_YEARLY_ID}`],
    });

    const getPlan = useCallback(async () => {
        let result: any = await memberstack.getCurrentMember();
        let method = ''
        let items = result.data && result.data.customFields as {
            name: any;
            phone: any;
        }

        setName(items.name!)
        setPhone(items.phone!)
        setEmail(result.data.auth.email!)

        result = result.data.planConnections
        if (result && result.length) method = result[0].planId.split('_')[1].split('-')[0]
        else method = ''
        setPlan(method)

    }, [plan])

    useEffect(() => {
        getPlan()
    }, [])

    return (
        <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[15px] md:pb-[50px] pb-[30px] text-[16px] md:text-[12px]">
            <div className={isLoading ? "flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[26px]" : "flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[26px]"}>
                <div className="block md:hidden">
                    <div className="hidden xs:block">
                        <div className="flex-1 flex flex-col gap-y-[20px] md:text-[14px] text-[16px]">
                            <span className="text-center">Y IS PRESENTED IN MODE</span>
                        </div>
                    </div>
                </div>
                <div className="md:block max-[500px]:block w-full block xs:hidden md:text-[12px] text-[16px]">
                    <span className="">Change plan</span>
                    <div className="flex flex-col gap-y-[3.6px] my-6">
                        <span><input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} value={name} className="bg-transparent outline-none w-full text-[16px] md:text-[12px]" /></span>
                        <span><input type="text" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} value={email} className="bg-transparent outline-none w-full text-[16px] md:text-[12px]" /></span>
                        <span><input type="text" placeholder="Phone number" onChange={(e) => setPhone(e.target.value)} value={phone} className="bg-transparent outline-none w-full text-[16px] md:text-[12px]" /></span>
                    </div>
                    <div className="flex flex-row gap-y-[3.6px] mb-7">
                        <div>
                            <MemberstackProtected onUnauthorized={<SignInModal />}>
                                {
                                    plan === 'monthly'
                                        ? <span className="cursor-pointer" onClick={async () => { setLoading(true); setPlan('yearly'); await updateYearlyPlan(); }}>Switch to yearly</span>
                                        : <span className="cursor-pointer" onClick={async () => { setLoading(true); setPlan('monthly'); await updateMonthlyPlan(); }}>Switch to monthly</span>
                                }
                            </MemberstackProtected>
                        </div>
                    </div>

                    {
                        isLoading
                            ? <span>Loading...</span>
                            : <span onClick={confirm} className="cursor-pointer">Update</span>
                    }
                    {
                        <div className="flex mt-10 flex-row items-center gap-x-[20px]">
                            <span className="cursor-pointer">{msg && msg}</span>
                        </div>
                    }
                </div>
            </div>
            {/* {
                isLoading &&
                <div className="flex flex-col justify-center items-center h-screen">
                    <img width={'15%'} src={loadingGif} alt="loading..." />
                </div>
            } */}
            <div className="md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                <div className="flex mt-10 flex-row items-center gap-x-[20px]">
                    <span onClick={() => navigation('/account/dashboard')} className="cursor-pointer">Go back</span>
                </div>
            </div>
        </div>
    )
}