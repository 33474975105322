import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useMemberstack } from "@memberstack/react";
import { getDateFormat1 } from '../utils';

export default function ChangePassword() {

    const navigation = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [msg, setMsg] = useState<string>("")

    const memberstack = useMemberstack();

    const confirm = async () => {
        if (!oldPassword || !newPassword) return
        setLoading(true);
        try {
            await memberstack.updateMemberAuth({
                oldPassword: oldPassword!,
                newPassword: newPassword!,
            });
            setLoading(false);
            setMsg("Successfully updated")
            setTimeout(() => {
                setMsg("")
            }, 5000)
        } catch (e: any) {
            setLoading(false);
            setMsg(e?.message);
            setTimeout(() => {
                setMsg("")
            }, 5000)
        }
    }

    useEffect(() => {
        document.getElementById("oldPasswordId")?.focus();
    }, [])

    return (

        <div className="w-full h-full flex flex-col px-[35px] sm:px-[33px] md:pt-[70px] pt-[12px] md:pb-[50px] pb-[30px] text-[16px] md:text-[12px]">
            <div className="flex-1 flex flex-col max-[479px]:justify-start md:justify-start justify-center gap-y-[30px]">
                <div className="block md:hidden">
                    <div className="hidden xs:block">
                        <div className="flex-1 flex flex-col gap-y-[20px] text-[16px] md:text-[12px]">
                            <span className="text-center">Y IS PRESENTED IN MODE</span>
                        </div>
                    </div>
                </div>
                <div className="md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                    <span className="">Change Password</span>

                    <div className="flex flex-col gap-y-[3.6px] my-5">
                        <input id="oldPasswordId" placeholder="Old password" type="password" value={oldPassword} onChange={(evt) => setOldPassword(evt.target.value)} className="bg-transparent outline-none w-full placeholder:text-black text-[16px] md:text-[12px]" />
                        <input placeholder="New Password" type="password" value={newPassword} onChange={(evt) => setNewPassword(evt.target.value)} className="bg-transparent outline-none placeholder:text-black w-full text-[16px] md:text-[12px]" />
                    </div>

                    {
                        isLoading ?
                            <span>Please wait...</span> :
                            <span onClick={confirm} className="cursor-pointer">Submit</span>
                    }

                </div>
                <div className="flex flex-row items-center gap-x-[20px]">
                    <span className="cursor-pointer">{msg && msg}</span>
                </div>
            </div>

            <div className="md:block max-[500px]:block w-full block xs:hidden text-[16px] md:text-[12px]">
                <div className="flex mt-10 flex-row items-center gap-x-[20px]">
                    <span onClick={() => navigation('/account/dashboard')} className="cursor-pointer">Go back</span>
                </div>
            </div>
        </div>
    )
}